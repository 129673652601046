<template>
  <CRow v-if="platformPermissionsLoaded && (checkPermission('loyalty.momentsenders.overview') || (checkPermission('loyalty.budgetcaps') && ['business', 'enterprise'].includes(productLicenses.loyalty_license_tag)))" class="loyalty budget_caps">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{$t('common.Configure')}}</span>
            </CCol>             
          </CRow>
        </CCardHeader>
        <CCardBody class="has_tabs pt-0 pb-0">
          <CRow class="pb-0">
            <CCol cols="12" lg="12" class="p-0">
              <b-tabs class="no_borders mb-0 no_padding" :class="{ 'hide_tabs' : modules.budget_caps === 0 }" type="is-boxed" :animated="false" v-model="activeTab">
                <b-tab-item v-if="checkPermission('loyalty.momentsenders.overview')">
                  <template slot="header">
                    <i class="mr-1 fas fa-users"/><span>{{ $t('common.Senders') }}</span>
                  </template>
                  <template>
                    <Senders :platformPermissions="platformPermissions"/>
                  </template>
                </b-tab-item>
                <b-tab-item v-if="modules.budget_caps === 1 && checkPermission('loyalty.budgetcaps') && ['business', 'enterprise'].includes(productLicenses.loyalty_license_tag)">
                  <template slot="header">
                    <i class="mr-1 fas fa-circle-exclamation"/><span>{{ $t('loyalty.Budget_caps') }}</span>
                  </template>
                  <template>
                    <BudgetCaps :productLicenses="productLicenses"/>
                  </template>
                </b-tab-item>
              </b-tabs>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'

import noPermission from '@/components/common/noPermission.vue';

import Senders from '@/views/loyalty/configure/Senders.vue';
import BudgetCaps from '@/views/loyalty/configure/BudgetCaps.vue';

export default {
  name: 'Configure',
  components: {
    noPermission,
    Senders,
    BudgetCaps
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      productLicenses: {
        loyalty_licence_tag: null
      },      
      modules: {},      
      platformPermissions: [],
      platformPermissionsLoaded: false,
      activeTab: 0 
    }
  },
  methods: {
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
        .then(res => {      
          this.platformPermissions = res.data.data;
          this.platformPermissionsLoaded = true;
        })
        .catch(err => {
          console.error(err); 
        });
    },
    checkPermission(permissionTag) {
      return this.platformPermissions.includes(permissionTag);
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }   
  },
  async mounted() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.productLicenses = await this.fetchProduuctLicenseData();

    this.checkModules();
    this.getPlatformPermissions();
  }
}
</script>